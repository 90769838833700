import React, {useContext} from "react";
import {Disclosure, Transition, Menu} from "@headlessui/react";
import {MenuIcon, XIcon} from "@heroicons/react/outline";
import {Fragment, useState, useCallback} from "react";
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import cubboLogo from "../assets/cubbo-white.png"
import {DASHBOARD, ORDERS, INTEGRATIONS, INVENTORY, INVOICES, REPLENISHMENTS, STORES, RETURNS, STORE_PATH, SETTINGS, WORK_ORDERS, BILLING} from "../navigation/constants";
import {UserContext} from "../hooks/UserContext";
import axiosInstance from "../helpers/axiosInstance";
import { ReturnsPendingCountBadge } from "../pages/Returns/ReturnsPendingCountBadge";
import {Notification} from "../components";
import { useTranslation } from 'react-i18next';
import {BellIcon} from "@heroicons/react/solid";
import AnnounceKit from "announcekit-react";
import Cookies from 'js-cookie';


export const TopMenu = () => {

    const { t, i18n } = useTranslation();


    const navigation = [
        { id:1 , name: `${i18n.t("topMenu.home")}`, href: DASHBOARD, storeRequired: true },
        { id:2 , name: `${i18n.t("topMenu.orders")}`, href: ORDERS, storeRequired: true },
        { id:3 , name: `${i18n.t("topMenu.returns")}`, href: RETURNS, storeRequired: true },
        { id:4 , name: `${i18n.t("topMenu.inventory")}`, href: INVENTORY, storeRequired: true },
        { id:5 , name: `${i18n.t("topMenu.replenishments")}`, href: REPLENISHMENTS, storeRequired: true, hideForPickNShip: true},
        { id:6 , name: `${i18n.t("topMenu.integrations")}`, href: INTEGRATIONS, storeRequired: true },
        { id:7 , name: `${i18n.t("topMenu.invoices")}`, href: INVOICES, storeRequired: true, onlyForBrazilStores: true },
        { id:8 , name: `${i18n.t("topMenu.billing")}`, href: BILLING, storeRequired: true },
        { id:9 , name: `${i18n.t("topMenu.settings")}`, href: SETTINGS, storeRequired: true },
    ]
    
    const external_navigation = [
        {id:5, name: `${i18n.t("topMenu.support")}`, href: 'https://cubbohelp.zendesk.com/hc/es', storeRequired: false, target: "blank"}
    ]
    
    const userNavigation = [
        { id: 6, name: `${i18n.t("topMenu.recover_pwd")}`,  href: '/' },
        { id: 4, name: `Terminos y Condiciones`,  href: '/' },
        { id: 7, name: `${i18n.t("topMenu.logout")}`, href: '/' },
    ]
    
    const languages = [
        { id: 8, name: `${i18n.t("topMenu.spanish")}` },
        { id: 9, name: `${i18n.t("topMenu.portuguese")}` },
        { id: 10, name: `${i18n.t("topMenu.english")}` },
    ]
    
    
    
    
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    
    const context = useContext(UserContext);
    const history = useHistory()

    const routeMatch = useRouteMatch({ path: STORE_PATH })
    const routeStoreId = routeMatch?.params?.storeId
    const getStoreUrl = (path) => {
        if (routeStoreId) {
            return STORE_PATH.replace(':storeId', routeStoreId) + path
        }
        if (context?.user?.current_store) {
            return STORE_PATH.replace(':storeId', context.user.current_store.id) + path
        }
        return ''
    }

    const [showNotification, setShowNotification] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)


    const handleClickNavigation = (e, item) => {
        e.preventDefault()
        if(item.id === 6 ){
            axiosInstance.post("/api/v1/reset-password",{email: context.user?.email}).then((res) => {
                setSuccessMessage(t("success_notification.instructions_with_password"))
                setShowNotification(true)    
            }).catch((res)=>{
                setErrorMessage(res.error)
                setShowNotification(true)
            })
        }
        if(item.id === 4){
            window.open("https://app.cubbo.com/tyc.pdf", "_blank");
        }
        if(item.id === 7 ){
            axiosInstance.post("/api/v1/logout", {}, {withCredentials: true}).then(() => {
                context.setUser({})
                history.push("/login")
            }).catch(()=>{
                alert("Error al deslogearse")
            })
        }
    }

    const changeLanguage = (e, item) => {
        e.preventDefault()
        if(item.id === 8 ){
            i18n.changeLanguage("es")
        }
        if(item.id === 9) {
            i18n.changeLanguage("pt")
        }
        if(item.id === 10) {
            i18n.changeLanguage("en")
        }
        Cookies.set('language', i18n.language);
    }

    const getUserAvatar = () => {
        return `https://ui-avatars.com/api/?name=${context.user?.first_name}+${context.user?.last_name}`
    }

    const getLanguageCode = () => {
        return i18n.language.toUpperCase()
    }

    const showNavigationItem = item => (
        !item.storeRequired || (item.storeRequired && context.user?.current_store)
    ) && (
        !item.hideForPickNShip || (context.user?.current_store?.store_type !== "PICK_AND_SHIP")
    ) && (
        !item.onlyForBrazilStores || context.user?.current_store?.warehouses[0]?.country === 'BR'
    ) && (
        item.href !== BILLING || (context.user?.current_store?.has_current_billing_configuration && context.user?.permissions?.read_store_billing_configuration && !["edgar@chabacano.mx", "larissaamz1@gmail.com", "manuelgarza2804@gmail.com", "valeriaovando25@gmail.com", "victorofir3@gmail.com"].includes(context?.user?.email)) 
        && ( //Remove this when BR billing activated for all stores
            context.user?.current_store?.warehouses[0]?.country !== 'BR' || context.user?.roles?.includes("ROLE_SUPER_ADMIN")
        )
    )

    return (
        <>
        <Notification show={showNotification} setShow={setShowNotification} type="success" title={t("success_notification.check_your_email")} description={successMessage}/>
        <Disclosure as="nav" className="bg-light-blue-500" aria-label="Global">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                        <div className="flex justify-between h-16">
                            <div className="flex items-center px-2 lg:px-0">
                                <div className="flex-shrink-0 flex items-center">
                                    <img
                                        className="h-8 w-auto"
                                        src={cubboLogo}
                                        alt="Workflow"
                                    />
                                </div>
                                <div className="hidden lg:ml-8 lg:flex lg:space-x-4 items-center">
                                    {(context.user?.permissions?.read_all_stores || context.user?.all_stores_ids?.length > 1) &&
                                        <Link
                                            to={STORES}
                                            className="rounded-md py-2 px-2 text-sm font-medium text-white hover:bg-light-blue-400"
                                            key={"web_Tiendas"}
                                            // onClick={()=>console.log("click " + STORES)}
                                        >
                                            {i18n.t("topMenu.stores")}
                                        </Link>
                                        
                                    }
                                    {navigation.map((item) => (
                                        
                                        showNavigationItem(item) &&
                                            <Link
                                            to={item.storeRequired ? getStoreUrl(item.href) : item.href}
                                            className="rounded-md py-2 px-2 text-sm font-medium text-white hover:bg-light-blue-400 content-center xl:whitespace-nowrap"
                                            key={"web_"+item.name}
                                            // onClick={()=>console.log("click " + item.href)}
                                            >
                                                {item.name}
                                                {item.href === RETURNS && 
                                                    <ReturnsPendingCountBadge/>
                                                }
                                            </Link>
                                    ))}
                                    <a className="rounded-md py-2 px-2 text-sm font-medium text-white hover:bg-light-blue-400" href="https://cubbohelp.zendesk.com/hc/es" target="_blank"> {i18n.t("topMenu.help")} </a>
                                </div>
                            </div>

                            <div className="flex items-center lg:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-light-blue-200 hover:text-white hover:bg-light-blue-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            
                            <div className="hidden lg:ml-4 lg:flex lg:items-center">
                                {/*<button className="flex-shrink-0 bg-light-blue-500 p-1 text-light-blue-200 rounded-full hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-light-blue-500 focus:ring-white">
                                    <span className="sr-only">Notificaitons</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button>*/}

                                {/* Profile dropdown */}
                                <Menu as="div" className="ml-4 relative flex-shrink-0">
                                {({ open }) => (
                                        <>
                                            <div>
                                                <Menu.Button className="flex text-base text-white">
                                                    {<>
                                                        {/* <span className="sr-only">Change Languaje</span>
                                                        <img className="h-8 w-8 rounded-full bg-white" src={getLanguageImage()} alt="" /> */}
                                                        {getLanguageCode()}
                                                    </>}
                                                </Menu.Button>
                                            </div>
                                            
                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items
                                                    static
                                                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                                                    style={{zIndex:999}}
                                                >
                                                    { languages.map((item) => (
                                                        <Menu.Item key={item.name}>
                                                            {({ active }) => (
                                                                <a
                                                                    href={item.href}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100' : '',
                                                                        'block px-4 py-2 text-sm text-gray-700'
                                                                    )}
                                                                    onClick={(e) => changeLanguage(e, item)}
                                                                >
                                                                    {item.name}
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    ))}
                                                </Menu.Items>
                                            </Transition>
                                        </>
                                    )}
                                </Menu>

                                <Menu as="div" className="ml-4 relative flex-shrink-0">
                                    {({ open }) => (
                                        <>
                                            <div>
                                                <Menu.Button className="bg-light-blue-500 rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-light-blue-500 focus:ring-white">
                                                    {<>
                                                        <span className="sr-only">Open user menu</span>
                                                        <img className="h-8 w-8 rounded-full" src={getUserAvatar()} alt="" />
                                                    </>}
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items
                                                    static
                                                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                                                    style={{zIndex:999}}
                                                >
                                                    { userNavigation.map((item) => (
                                                        <Menu.Item key={item.name}>
                                                            {({ active }) => (
                                                                <a
                                                                    href={item.href}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100' : '',
                                                                        'block px-4 py-2 text-sm text-gray-700'
                                                                    )}
                                                                    onClick={(e) => handleClickNavigation(e, item)}
                                                                >
                                                                    {item.name}
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    ))}
                                                </Menu.Items>
                                            </Transition>
                                        </>
                                    )}
                                </Menu>

                                {/* <div className="whitespace-nowrap">
                                    <AnnounceKit
                                        widget="https://announcekit.co/widgets/v2/2i2Rm8"
                                        lang={i18n.language}
                                        user={{
                                            id: context.user?.id,
                                            // Optional additional fields
                                            email: context.user?.email,
                                            name: context.user?.first_name+" "+context.user?.last_name,
                                            language: i18n.language
                                        }
                                        }
                                    >
                                        <BellIcon className="ml-4 inline h-6 w-6 text-white" aria-hidden="true" />
                                    </AnnounceKit>
                                </div> */}

                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="lg:hidden">
                        <div className="pt-2 pb-3 px-2 space-y-1">
                            {(context.user?.permissions?.read_all_stores || context.user?.all_stores_ids?.length > 1) &&
                                <Link
                                    to={STORES}
                                    className="rounded-md py-2 px-2 text-sm font-medium text-white hover:bg-light-blue-400"
                                    key={"web_Tiendas"}
                                    // onClick={()=>console.log("click " + STORES)}
                                >
                                    Tiendas
                                </Link>
                            }
                            {navigation.map((item) => (
                                (!item.storeRequired || (item.storeRequired && context.user?.current_store)) &&
                                    <Link
                                        to={item.storeRequired ? getStoreUrl(item.href) : item.href}
                                        className="block rounded-md py-2 px-3 text-base font-medium text-white hover:text-white hover:bg-light-blue-400"
                                        key={`mobile_${item.id}`}
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            <a className="rounded-md py-2 px-2 text-sm font-medium text-white hover:bg-light-blue-400" href="https://cubbohelp.zendesk.com/hc/es" target="_blank">{i18n.t("topMenu.help")}</a>
                        </div>
                        <div className="pt-4 pb-3 border-t border-light-blue-500">
                            <div className="flex items-center px-4">
                                <div className="flex-shrink-0">
                                    <img className="h-10 w-10 rounded-full" src={getUserAvatar()} alt="" />
                                </div>
                                <div className="ml-3">
                                     {console.log(context.user)}
                                    <div className="text-base font-medium text-white">{context.user?.first_name}</div>
                                    <div className="text-sm font-medium text-light-blue-200">{context.user?.email}</div>
                                </div>
                                <button className="ml-auto flex-shrink-0 bg-light-blue-500 rounded-full p-1 text-light-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-light-blue-500 focus:ring-white">
                                    <span className="sr-only">View notifications</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="mt-3 px-2">
                                {userNavigation.map((item) => (
                                    <a
                                        key={`userNavigationMovile${item.id}`}
                                        href={item.href}
                                        className="block rounded-md py-2 px-3 text-base font-medium text-light-blue-200 hover:text-white hover:bg-light-blue-400"
                                        onClick={(e) => handleClickNavigation(e, item)}
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div> 
                        </div>
                    </Disclosure.Panel>
                </>
            )}       
        </Disclosure>  
        </> 
    )
}